<script>
import axios from "axios";
import Swal from "sweetalert2";

import appConfig from "@/app.config";
import useVuelidate from "@vuelidate/core";
import $ from "jquery";

/**
 * Login component
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  page: {
    title: "Tambah Absen Rapat Direksi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
  },
  data() {
    return {
      configuration: [],
      nr_id: this.$route.params.id,
      posisi_jabatan: [],
      timeline: [],
      posisi_jabatan_selected: "",
      formCheckin: false,
      timeline_id: null,
    };
  },
  mounted() {
    // GET KONFIG
    if ("vue-drawing-canvas" in window.localStorage) {
      this.initialImage = JSON.parse(
        window.localStorage.getItem("vue-drawing-canvas")
      );
    }
    this.getPosisiJabatan();
    this.getKonfig();
  },
  methods: {
    getKonfig(){
      axios
      .get(
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "/api/master/konfig"
      )
      .then((response) => {
        var response_data = response.data;
        if (response_data.code == 200) {
          let clear_data_config = [];
          $.each(response_data.list_data, function (indexInArray, valueOfElement) {
            clear_data_config['LOGO'] = valueOfElement[0].value;
            clear_data_config['LOGIN_BACKGROUND'] = valueOfElement[1].value;
            clear_data_config['TITLE_APPLICATION'] = valueOfElement[2].value;
            clear_data_config['APPLICATION'] = valueOfElement[3].value;
            clear_data_config['VERSION'] = valueOfElement[4].value;
            clear_data_config['COPYRIGHT'] = valueOfElement[5].value;
            clear_data_config['TERM_CONDITION'] = valueOfElement[6].value;
            clear_data_config['CATATAN_CV'] = valueOfElement[7].value;
          });

          this.configuration = clear_data_config;
        }
      });
    },

    getPosisiJabatan() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/master/posisijabatan/jabatanNotulen?nr_id="+self.nr_id
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.posisi_jabatan = response_data.jabatan;
            self.timeline = response_data.timeline;
          }
        });
    },
    
    StoreData() {
      let self = this;

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      if(self.posisi_jabatan_selected == null || self.posisi_jabatan_selected == ""){
        Swal.fire({
          icon: 'error',
          title: 'Gagal!',
          text: "Pastikan formulir terisi lengkap",
        });
        return false;
      }
      var mpj_id = self.posisi_jabatan_selected?.mpj_id;
      if (mpj_id) {
        mpj_id = self.posisi_jabatan_selected?.mpj_id;
      } else {
        mpj_id = '';
      }
      
      var type = "Sirkuler Masuk";
      if(this.posisi_jabatan.length == 1){
        type = "Sirkuler Keluar";
      }

      var FormData = require("form-data");
      var data = new FormData();
      data.append("tnr_posisi_jabatan_id", mpj_id);
      data.append("tnr_nr_id", self.nr_id);
      data.append("tnr_jenis", type);

      var url_link = process.env.VUE_APP_BACKEND_URL_VERSION + "api/monitoring/notulen/checkinout";
      var config = {
        method: "post",
        url: url_link,
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          if (response_data.status != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Berhasil melakukan absen",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                Swal.close();
                location.reload();
                // self.$router.push({ name: "logout" });
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>
<style>
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.bg-login {
  max-width: 100%;
  height: auto;
  padding-top: 25px;
  padding-bottom: 30px;
}

.wrap-login100 {
  width: 100%;
}
</style>
<template>
  <div class="container bg-login" v-bind:style="{
    background: 'url(' + configuration.LOGIN_BACKGROUND + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no repeat',
    backgroundPosition: 'center',
  }">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-11 col-xl-10">
        <div class="wrap-login100 mt-3 mb-3 p-2" style="background: #fff !important; min-height: 650px;">
          <div v-if="formCheckin == false">
            <form @submit.prevent="StoreData">
              <span class="login100-form-logo">
                <img :src="configuration.LOGO" width="150" />
              </span>
              <span class="login100-form-title p-b-15 p-t-15 mb-3">
                <b>Check-In Notulen Radisi</b>
              </span>
              <div class="row">
                <div class="col-12">
                  <div class="mb-3">
                    <label for="name">Pilih Jabatan <span class="text-danger">* wajib diisi</span></label>
                    <v-select placeholder="Pilih Jabatan" v-model="posisi_jabatan_selected" label="mpj_nama"
                      :options="posisi_jabatan"></v-select>
                  </div>
                </div>
              </div>
              <div>
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Checklist</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="timeline == ''">
                      <td class="text-center" colspan="6">
                        Data Tidak Tersedia
                      </td>
                    </tr>
                    <tr v-else v-for="(row_data, key_data) in timeline" :key="key_data" >
                      <td class="text-center">
                        {{ key_data + 1 }}
                      </td>
                      <td class="text-center">
                        {{ row_data.tnr_posisi_jabatan }}
                      </td>
                      <td class="text-center">
                        <i class="fa fa-check"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="text-end pt-5 mt-3" style="margin-top: 200px;" v-if="posisi_jabatan.length == 1">
                <b-button type="submit" variant="primary" class="ms-1"><i class="fa fa-save"></i> Check Out</b-button>
              </div>
              <div class="text-end pt-5 mt-3" style="margin-top: 200px;" v-else>
                <b-button type="submit" variant="success" class="ms-1"><i class="fa fa-save"></i> Check In</b-button>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
</template>
